import React from 'react';
import routes from './home.routes.json';
import Layout from '@components/Layout';
import Home from './Home';
import { canonicalLocaleUrl } from '../../core/url';

export default function action(context, params) {
  console.log('params', params);

  if (!params.path) {
    const { parent, ...route } = context?.route;
    const { children, ...parentRoute } = parent;

    return {
      ...route,
      params,
      parent: parentRoute,
      name: context.name,
      index: context.index,
      component: (
        <Layout>
          <Home />
        </Layout>
      ),
      canonicalUrl: canonicalLocaleUrl("home", context.locale, params),
      chunks: ['home'],
      hreflang: routes,
    };
  }
  return null;
}
